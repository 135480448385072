.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes growDown {
  0% {
    transform: scaleY(0);
  }
  80% {
    transform: scaleY(1.1);
  }
  100% {
    transform: scaleY(1);
  }
}
/* .-z-1 {z-index: -1;} */
.border-50 {
  border-radius: 50%;
}
.origin-0 {
  transform-origin: 0%;
}
.appearance-none {
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
}
.menu-transition {
  transform-origin: top center;
}
input:focus ~ label,
input:not(:placeholder-shown) ~ label,
textarea:focus ~ label,
textarea:not(:placeholder-shown) ~ label,
select:focus ~ label,
select:not([value='']):valid ~ label,
input:disabled ~ label,
select:disabled ~ label {
  --tw-translate-x: 0.25rem;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-scale-x: 0.75;
  --tw-scale-y: 0.75;
  --tw-translate-y: -1rem;
}

input:focus ~ label,
select:focus ~ label {
  --tw-text-opacity: 1;
  left: 0px;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.bg-caret_down {
  background: url('../public/images/sprites.png') -5px -5px;
  width: 26px;
  height: 26px;
}
.bg-close {
  background: url('../public/images/sprites.png') -41px -5px;
  width: 26px;
  height: 26px;
}
.bg-copy {
  background: url('../public/images/sprites.png') -77px -5px;
  width: 26px;
  height: 26px;
}
.bg-delete {
  background: url('../public/images/sprites.png') -113px -5px;
  width: 26px;
  height: 26px;
}
.bg-edit {
  background: url('../public/images/sprites.png') -149px -5px;
  width: 26px;
  height: 26px;
}
.bg-search {
  background: url('../public/images/sprites.png') -185px -5px;
  width: 26px;
  height: 26px;
}
.bg-view {
  background: url('../public/images/sprites.png') -221px -5px;
  width: 26px;
  height: 26px;
}
.bg-drag {
  background: url('../public/images/sprites.png') -257px -5px;
  width: 26px;
  height: 26px;
}
.bg-tick {
  background: url('../public/images/sprites.png') 34px -5px;
  width: 26px;
  height: 26px;
}
.react-tel-input .form-control {
  width: 100%;
  padding: 14.5px 14px 14.5px 60px;
}
.rc-time-picker-input {
  height: 55px;
  padding-top: 20px;
  padding-left: 15px;
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  color: #000;
  font-size: 16px;
}

.rc-time-picker-clear {
  display: none;
}
